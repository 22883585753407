
import { defineComponent } from "vue";
import { RouteLocationRaw } from "vue-router";

type MenuItem = {
  key: string;
  title: string;
  icon: string;
  navTarget: RouteLocationRaw;
  routeGroup?: string;
};

type MenuSection = {
  key: string;
  sectionHeader?: {
    title: string;
    actions?: Array<{
      key: string;
      title: string;
      icon: string;
      handler: (event: MouseEvent) => void;
    }>;
  };
  items: MenuItem[];
};

export default defineComponent({
  computed: {
    menuSections(): MenuSection[] {
      return [
        {
          key: "main",
          items: [
            {
              key: "organizations",
              title: "Empresas",
              icon: "briefcase",
              navTarget: { name: "organizations" },
              routeGroup: "organizations",
            },
            {
              key: "users",
              title: "Intérpretes",
              icon: "users",
              navTarget: { name: "users" },
              routeGroup: "users",
            },
            {
              key: "producers",
              title: "Productores",
              icon: "crosshair",
              navTarget: { name: "producers" },
              routeGroup: "producers",
            },
            {
              key: "bulk-email",
              title: "Mailing masivo",
              icon: "mail",
              navTarget: { name: "bulk-email" },
              routeGroup: "bulk-email",
            },
          ],
        },
      ];
    },
  },
  methods: {
    logOut() {
      this.$auth.logOut();
    },
  },
});
