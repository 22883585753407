import { Plugin } from "vue";
import { RouteLocationRaw } from "vue-router";

import { logIn } from "@/api/Auth";
import router from "@/router";

export class Authentication {
  constructor(
    public loginRoute: RouteLocationRaw | null = null,
    public afterLoginRoute: RouteLocationRaw | null = null
  ) {}

  public getAccessToken(): string | null {
    // return "Od1Rzc173rt@1qJJp&GZ2OU1";
    return localStorage.getItem("auth:token");
  }

  public storeAccessToken(token: string): void {
    return localStorage.setItem("auth:token", token);
  }

  public clearAccessToken(): void {
    return localStorage.removeItem("auth:token");
  }

  public isLoggedIn(): boolean {
    // return true;
    return this.getAccessToken() !== null;
  }

  public async logIn(adminToken: string): Promise<void> {
    const token = await logIn(adminToken);
    this.storeAccessToken(token);

    if (this.afterLoginRoute) {
      router.push(this.afterLoginRoute);
    }
  }

  // public async logIn(username: string, password: string) {
  //   const token = await logIn(username, password);
  //   this.storeAccessToken(token);

  //   if (this.afterLoginRoute) {
  //     router.push(this.afterLoginRoute);
  //   }
  // }

  public async logOut(): Promise<void> {
    this.clearAccessToken();

    if (this.loginRoute) {
      router.push(this.loginRoute);
    }
  }
}

export const auth = new Authentication({ name: "login" }, { name: "start" });

export const AuthenticationPlugin: Plugin = {
  install(app) {
    app.config.globalProperties.$auth = auth;
  },
};

export default auth;
