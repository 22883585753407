import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import auth from "@/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "start",
    redirect: { name: "organizations" },
  },
  {
    path: "/bulk-email",
    name: "bulk-email",
    component: () => import("@/components/Views/BulkEmail/BulkEmailView.vue"),
    meta: { title: "Mailing masivo", routeGroup: "bulk-email" },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/components/Login/LoginView.vue"),
    meta: { public: true, isLogin: true, useLayout: false },
  },
  {
    path: "/organizations",
    name: "organizations",
    component: () =>
      import("@/components/Views/Organizations/OrganizationsView.vue"),
    meta: { title: "Empresas", routeGroup: "organizations" },
  },
  {
    path: "/organizations/create",
    name: "organizations/create",
    component: () =>
      import("@/components/Views/Organizations/CreateOrganizationView.vue"),
    meta: { title: "Empresas", routeGroup: "organizations" },
  },
  {
    path: "/organizations/:id",
    name: "organizations/edit",
    component: () =>
      import("@/components/Views/Organizations/EditOrganizationView.vue"),
    meta: { title: "Empresas", routeGroup: "organizations" },
  },
  {
    path: "/producers",
    name: "producers",
    component: () => import("@/components/Views/Producers/ProducersView.vue"),
    meta: { title: "Productores", routeGroup: "producers" },
  },
  {
    path: "/producers/:id",
    name: "producers/edit",
    component: () =>
      import("@/components/Views/Producers/EditProducersView.vue"),
    meta: { title: "Productores", routeGroup: "producers" },
  },
  {
    path: "/users",
    name: "users",
    component: () => import("@/components/Views/Users/UsersView.vue"),
    meta: { title: "Intérpretes", routeGroup: "users" },
  },
  {
    path: "/users/:id",
    component: () => import("@/components/Views/Users/EditUserView.vue"),
    meta: { title: "Intérpretes", routeGroup: "users" },
    children: [
      {
        path: "",
        name: "users/edit/basic-profile",
        component: () => import("@/components/Views/Users/UserBasicInfo.vue"),
        props: true,
      },
      {
        path: "professional-profile",
        name: "users/edit/professional-profile",
        props: true,
        component: () =>
          import("@/components/Views/Users/UserProfessionalProfile.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  if (!to.meta.public && !auth.isLoggedIn()) {
    return { name: "login" };
  }
  if (to.meta.isLogin && auth.isLoggedIn() && auth.afterLoginRoute) {
    return auth.afterLoginRoute;
  }
});

export default router;
