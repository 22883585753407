import axios from "axios";

import auth from "@/auth";

axios.defaults.baseURL = process.env.VUE_APP_API_ADMIN_BASE_URL;
axios.defaults.responseType = "json";

axios.interceptors.request.use((config) => {
  if (config && config.authenticate) {
    const token = auth.getAccessToken();
    if (token) {
      config.headers["X-Authorization-Admin-Token"] = token;
    } else {
      throw Error(
        "This endpoint requires authentication and no access token is available"
      );
    }
  }
  return config;
});

axios.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 401) {
    auth.logOut();
  }
  return Promise.reject(error);
});
