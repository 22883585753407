import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-layout" }
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = { class: "main-content px-3 px-md-4 px-xl-5 py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_navbar = _resolveComponent("app-navbar")!
  const _component_app_sidebar = _resolveComponent("app-sidebar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_navbar),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_sidebar),
      _createElementVNode("main", _hoisted_3, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}