
import { defineComponent } from "vue";

import AppNavbar from "@/components/Layout/AppNavbar.vue";
import AppSidebar from "@/components/Layout/AppSidebar.vue";

export default defineComponent({
  computed: {},
  components: {
    AppNavbar,
    AppSidebar,
  },
});
