import axios from "axios";

// eslint-disable-next-line import/prefer-default-export
export async function logIn(adminToken: string): Promise<string> {
  // Make dummy request to verify token is correct (there is no dedicated endpoint yet)
  try {
    await axios.get("/organizations", {
      headers: { "X-Authorization-Admin-Token": adminToken },
    });
    return adminToken;
  } catch (error) {
    throw new Error("Could not log in (invalid token?)");
  }
}
